export const VIGO_PURCHASE_ORDER_EN = {
  all: 'ALL',
  created: 'CREATED',
  awaiting_payment: 'AWAITING PAYMENT',
  paid: 'PAID',
  cancelled: 'CANCELLED',
  title: 'VIGO PURCHASE ORDER',
  messageCancel:
    'Are you sure to REJECT this Purchase Order from Vigo? This action CANNOT be undone.',
  messageConfirm:
    'Are you sure to CONFIRM that you can fully serve this Purchase Order from Vigo?',
}
