import {
  DISTRIBUTOR_PURCHASE_ORDER_ALL,
  DISTRIBUTOR_PURCHASE_ORDER_AWAITING_PAYMENT,
  DISTRIBUTOR_PURCHASE_ORDER_CANCELLED,
  DISTRIBUTOR_PURCHASE_ORDER_CLOSED,
  DISTRIBUTOR_PURCHASE_ORDER_CREATED,
  DISTRIBUTOR_PURCHASE_ORDER_PAID,
} from 'utils/distributorPurchaseOrder'

const DISTRIBUTOR_PURCHASE_ORDER_STATUS_VI = {
  [DISTRIBUTOR_PURCHASE_ORDER_ALL]: 'TẤT CẢ',
  [DISTRIBUTOR_PURCHASE_ORDER_CREATED]: 'MỚI',
  [DISTRIBUTOR_PURCHASE_ORDER_AWAITING_PAYMENT]: 'CHỜ THANH TOÁN',
  [DISTRIBUTOR_PURCHASE_ORDER_PAID]: 'ĐÃ THANH TOÁN',
  [DISTRIBUTOR_PURCHASE_ORDER_CLOSED]: 'ĐÓNG',
  [DISTRIBUTOR_PURCHASE_ORDER_CANCELLED]: 'ĐÃ HỦY',
}

export const DISTRIBUTOR_PURCHASE_ORDER_VI = {
  purchaseOrder: 'Đơn mua hàng',
  vigoQuotationNumber: 'Mã báo giá Vigo',
  detail: 'Chi tiết báo giá',
  statusHistory: 'Lịch sử thay đổi',
  no: 'Thứ tự',
  createdDate: 'Ngày tạo',
  updatedDate: 'Ngày cập nhật',
  updateBy: 'Người cập nhật',
  relatedDocuments: 'Thông tin liên quan',
  deliveryTo: 'Vận chuyển đến',
  quotationDetails: 'Chi tiết báo giá',
  shipment: 'Tuyến hàng',
  vigoQuotation: 'Báo giá từ Vigo',
  deliveredBy: 'Giao hàng bởi',
  noOfItem: 'Số lượng',
  totalAmount: 'Tổng giá trị',
  image: 'Hình ảnh',
  name: 'Tên sản phẩm',
  selectWarehouse: 'Chọn kho hàng',
  actualOfferPrice: 'Giá đề nghị thực tế',
  vigoOfferPrice: 'Giá đề nghị từ Vigo',
  requestedQuantity: 'Số lượng yêu cầu',
  actualTotalAmount: 'Tổng giá trị thực tế',
  totalPriceDifferent: 'Tổng giá chênh lệch',
  packType: 'Đơn vị đóng gói',
  estimatedWeight: 'Trọng lượng ước tính (kg)',
  confirm: 'Xác nhận',
  warehouse: 'Kho hàng',
  distributor: 'Nhà phân phối',
  creationTime: 'Thời gian tạo',
  startDate: 'Ngày bắt đầu',
  endDate: 'Ngày kết thúc',
  search: 'Tìm kiếm',
  reset: 'Cài lại',
  refresh: 'Làm mới',
  created: 'MỚI',
  from: 'Từ',
  to: 'Đến',
  currentStatus: 'Trạng thái hiện tại',
  requestDate: 'Ngày yêu cầu',
  confirmDeadline: 'Thời gian xác nhận',
  totalValue: 'Tổng giá trị',
  status: 'Trạng thái',
  pre: 'Trang trước',
  next: 'Trang kế tiếp',
  purchaseOrderNumber: 'Mã đơn mua hàng',
  purchaseDetails: 'Chi tiết đơn mua hàng',
  purchasedByFrom: 'Mua hàng bởi (Từ)',
  supplierTo: 'Nhà cung cấp (Đến)',
  nationalDrugCode: 'Mã dược quốc gia',
  batchNumber: 'Số lô',
  shelfLifeMonth: 'Thời gian sử dụng theo tháng',
  manufacturingDate: 'Ngày sản xuất',
  expirationDate: 'Hạn sử dụng',
  quantity: 'Số lượng',
  productSku: 'Tên sản phẩm - SKU',
  discountPercent: 'Chiết khấu',
  beforeDiscount: 'Trước chiết khấu',
  actualPrice: 'Giá thực tế',
  vatPrice: 'Giá sau thuế',
  distributor_pickup: 'Nhà phân phối giao',
  purchase_request: 'Yêu cầu mua hàng',
  master_quotation: 'Giá tổng',
  idNumber: 'Mã',
  cancelAndReplace: 'Hủy và Thay đổi',
  messCancelAndReplace: 'Hủy và thay đổi thành công!',
  newDPO: 'DPO mới',
  replacedBy: 'Thay thế bởi DPO',
  ...DISTRIBUTOR_PURCHASE_ORDER_STATUS_VI,
}
